@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
/* *------------------------------------------------------------* /
   * Normalizer
/* *------------------------------------------------------------* */

*,
::before,
::after {
	box-sizing: border-box;
	margin-top: 0;
	padding: 0;
	margin: 0;
}

html {
	-moz-tab-size: 4;
	tab-size: 4;
	line-height: 1;
	-webkit-text-size-adjust: 100%; 
}

body {
	margin: 0;
  font-family: system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
}

hr {
	height: 0; 
	color: inherit; 
}

abbr[title] {
	-webkit-text-decoration: underline dotted;
	        text-decoration: underline dotted;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover, a:focus {
  text-decoration: underline;
}

b,
strong {
	font-weight: bolder;
}

code,
kbd,
samp,
pre {
	font-family:
		ui-monospace,
		SFMono-Regular,
		Consolas,
		'Liberation Mono',
		Menlo,
		monospace; 
	font-size: 1em; 
}

small {
	font-size: 80%;
}

sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sub {
	bottom: -0.25em;
}

sup {
	top: -0.5em;
}

table {
	text-indent: 0; 
	border-color: inherit; 
}

img {
  max-width: 100%;
	margin: 0;
	padding: 0;
}

button,
input,
optgroup,
select,
textarea {
	font-family: inherit; 
	font-size: 100%; 
	line-height: 1.15; 
	margin: 0; 
	background-color: transparent;
}

button,
select { 
	text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
	-webkit-appearance: none;
	appearance: none;
	border: none;
	padding: 0;
}

::-moz-focus-inner {
	border-style: none;
	padding: 0;
}

:-moz-focusring {
	outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
	box-shadow: none;
}

legend {
	padding: 0;
}

progress {
	vertical-align: baseline;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
	height: auto;
}


[type='search'] {
	-webkit-appearance: textfield; /* 1 */
	outline-offset: -2px; /* 2 */
}

::-webkit-search-decoration {
	-webkit-appearance: none;
}

::-webkit-file-upload-button {
	-webkit-appearance: button; 
	font: inherit; 
}

summary {
	display: list-item;
}

ul {
  margin: 0.5em 0;
}

ul li {
  margin: 0;
  list-style: none;
}

/* *------------------------------------------------------------* /
   * Template Base
/* *------------------------------------------------------------* */

body {
	overflow: hidden;
}

p {
  line-height: 1.5;
  margin: 0;
}

#application-canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
	margin: 0 !important;
	padding: 0 !important;
	pointer-events: auto;
}

#root {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 899;
  pointer-events: none;
  margin: 0 !important;
  padding: 0 !important;
}

.eighth-wall-logo {
  max-width: 110px;
  position: absolute;
  left: 50%;
  bottom: 1rem;
  transform: translateX(-50%);
  margin-bottom: 0;
	filter: invert(1);
}

@font-face {
  font-family: "Bebas Neue";
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/BebasNeue.902ec77a.otf);
}

@font-face {
  font-family: "Bebas Neue";
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/BebasNeueBold.a20da9a8.otf);
}

@font-face {
  font-family: "Source Serif Pro";
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/SourceSerifPro-Regular.0dc42687.otf);
}

@font-face {
  font-family: "Source Serif Pro";
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/SourceSerifPro-Bold.288798b7.otf);
}
